import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import SectionUtil from 'Utils/content/section-util';
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';

// Components
import PageClasses from '../../components/page-classes';
import ProfileVideos from '../../containers/profile-videos';
import ProfileFlips from '../../containers/profile-flips';
import ProfileLikes from '../../containers/profile-likes';
import ProfileStoryboards from '../../containers/section/profile-storyboards';
import NoContentGuard from '../../components/no-content-guard';
import SectionHeader from '../../components/section-header';
import SectionTiles from '../../components/section-tiles';
import { NavFromContext } from 'ComponentLibrary/context';
import VisibilityScrollToBottomTracker from '../../components/visibility-scroll-to-bottom-tracker';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

function Profile({
  uid,
  section,
  items,
  magazines,
  followers,
  getSectionFollowers,
  followersNextPageKey,
  followersLoading,
  usageTrackReachedSectionEnd,
  isAmp,
  t,
}) {
  const pageClasses = classNames({
    'section-view': true,
    'profile-view': section && section.isProfile,
    amp: isAmp,
  });

  const hasItems = SectionUtil.hasItems(section, items);
  const hasStoryboards = SectionUtil.hasStoryboards(section);
  const hasFollowers = followers.length !== 0;
  const hasMagazines = magazines.length !== 0;

  const fetchNextFollowersPage = followersNextPageKey
    ? () => getSectionFollowers(section, uid, followersNextPageKey)
    : null;

  return (
    <NavFromContext.Provider value={USAGE_NAV_FROMS.OTHER_PROFILE}>
      <PageClasses className={pageClasses} enableFullWidth>
        <div className="centered-column">
          <SectionHeader section={section} key={0} />
          <NoContentGuard
            key={1}
            hasContent={hasMagazines || hasFollowers || hasItems}
          >
            {hasStoryboards && <ProfileStoryboards section={section} />}
            {hasMagazines && (
              <SectionTiles anchorId="magazines" sections={magazines} />
            )}
            {hasFollowers && (
              <SectionTiles
                anchorId="followers"
                sections={followers}
                title={t('follower_plural')}
                loadNext={fetchNextFollowersPage}
                loadingNext={followersLoading}
                collapsed
              />
            )}
            <ProfileVideos uid={section.userid} infinite={false} />
            <ProfileLikes uid={section.userid} infinite={false} collapsed />
            <ProfileFlips uid={section.userid} infinite immediateInfinite />
            <VisibilityScrollToBottomTracker
              onScrolledToBottom={() => usageTrackReachedSectionEnd(section)}
            />
          </NoContentGuard>
        </div>
      </PageClasses>
    </NavFromContext.Provider>
  );
}

Profile.propTypes = {
  uid: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  items: PropTypes.array,
  magazines: PropTypes.array,
  followers: PropTypes.array,
  getSectionFollowers: PropTypes.func.isRequired,
  followersNextPageKey: PropTypes.string,
  followersLoading: PropTypes.bool,
  t: PropTypes.func,
  isAmp: PropTypes.bool.isRequired,
  unfollowSection: PropTypes.func,
  usageTrackReachedSectionEnd: PropTypes.func,
};

Profile.defaultProps = {
  items: [],
  magazines: [],
  followers: [],
  followersNextPageKey: null,
  followersLoading: false,
};

export default connector(connectAmp, connectAuthentication)(withT(Profile));
