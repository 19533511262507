import React from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

// Utils
import isIntegerMatch from 'Utils/is-integer-match';

// Components
import GenericSectionHeader from './generic-section-header';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

const ProfileHeader = codeSplitLoad('ProfileHeader');

/**
 * This component wraps more specific Section headers. In most use cases, this component
 * should be used as opposed to the more specific header components. This component also
 * includes shared functionality among header components.
 */
function SectionHeader({ section, uid, path, topicDescriptions }) {
  if (section.isProfile || section.isCommunityGroup) {
    // For sections that are feedType: profile, check the userid.
    return (
      <ProfileHeader
        section={section}
        isOwnProfile={isIntegerMatch(uid, section.userid)}
      />
    );
  }
  return (
    <GenericSectionHeader
      section={section}
      topicDescriptions={topicDescriptions}
      path={path}
    />
  );
}

SectionHeader.propTypes = {
  section: PropTypes.object.isRequired,
  uid: PropTypes.number,
  path: PropTypes.string,
  topicDescriptions: PropTypes.array,
};

SectionHeader.defaultProps = {
  uid: null,
  coverItem: null,
  topicDescriptions: null,
  path: null,
};

export default connector(connectAuthentication)(SectionHeader);
