import { connect } from 'react-redux';
import { getProfileFlips } from '../redux/actions/profile-actions';
import ProfileSectionItems from '../components/profile-section-items';

import FlapUtil from 'Utils/content/flap-util';
import SectionUtil from 'Utils/content/section-util';
import withT from 'ComponentLibrary/hocs/withT';

const mapStateToProps = (
  { sections },
  { uid: userid, t, collapsed, immediateInfinite },
) => {
  const remoteId = FlapUtil.getRemoteidByUserid(userid);
  const section = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(remoteId, sections.entries),
  );
  const isLoading = SectionUtil.isSectionLoading(
    section.requestedRemoteId,
    sections.loading,
  );

  const { items, neverLoadMore } = section;
  return {
    section,
    items,
    neverLoadMore,
    isLoading,
    name: t('flip_plural'),
    id: 'flips',
    collapsed,
    immediateInfinite,
  };
};

const mapDispatchToProps = (dispatch, { uid: userid }) => ({
  load: () => dispatch(getProfileFlips(userid)),
  loadNextPage: () =>
    dispatch((_, getState) => {
      const { sections } = getState();
      const remoteId = FlapUtil.getRemoteidByUserid(userid);
      const section = FlapUtil.getSectionByRemoteId(remoteId, sections.entries);
      return dispatch(getProfileFlips(userid, section));
    }),
});

const ProfileFlipsContainer = withT(
  connect(mapStateToProps, mapDispatchToProps)(ProfileSectionItems),
);

export default ProfileFlipsContainer;
