import { connect } from 'react-redux';
import { getSection } from '../../redux/actions/section-actions';
import ProfileStoryboards from '../../views/section/profile-storyboards';
import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';
import { bindActionCreators } from 'redux';

const mapStateToProps = ({ sections, auth }, { section }) => {
  const { userid } = section;
  const remoteid = FlapUtil.getUserStoryboardSectionId(userid);
  const storyboardSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(remoteid, sections.entries),
  );

  const isLoading =
    storyboardSection &&
    SectionUtil.isSectionLoading(
      storyboardSection.requestedRemoteId,
      sections.loading,
    );

  return {
    uid: auth.uid,
    storyboardSection,
    items: storyboardSection.items,
    neverLoadMore: storyboardSection.neverLoadMore,
    nextPageKey: storyboardSection.nextPageKey,
    previousRawItems: storyboardSection.rawItems,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getSection }, dispatch);

const ProfileStoryboardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileStoryboards);

export default ProfileStoryboardsContainer;
