import { connect } from 'react-redux';
import { usageTrackReachedSectionEnd } from 'Webapp/shared/app/redux/actions/usage-actions';
import { getSectionFollowers } from '../../redux/actions/section-actions';
import Profile from '../../views/section/profile';

import FlapUtil from 'Utils/content/flap-util';
import { bindActionCreators } from 'redux';

const mapStateToProps = ({ sections }, { section }) => {
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(section.remoteid, sections.entries),
  );

  return {
    items: currentSection.items,
    magazines: currentSection.magazineProjections,
    followers: currentSection.followerProjections,
    followersNextPageKey: currentSection.followersNextPageKey,
    followersLoading: currentSection.followersLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getSectionFollowers, usageTrackReachedSectionEnd },
    dispatch,
  );

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;
