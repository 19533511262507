import React from 'react';
import PropTypes from 'prop-types';

// Utils
import SectionUtil from 'Utils/content/section-util';

// Components
import ItemList, {
  ITEM_LIST_STYLE_MODIFIERS,
} from '../views/section/item-list';
import LoadMore from '../components/load-more';
import CollapsableAnchorSection from '../components/collapsable-anchor-section';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

class ProfileSectionItems extends React.Component {
  componentDidMount() {
    const { section, load } = this.props;

    if (!section || section.placeholder) {
      load();
    }
  }

  render() {
    const {
      id,
      infinite,
      isAmp,
      isLoading,
      items,
      loadNextPage,
      name,
      neverLoadMore,
      section,
      collapsed,
      immediateInfinite,
    } = this.props;
    const hasItems = SectionUtil.hasItems(section, items);
    const header = (
      <h2 className="layout-section__header ui-text--title">{name}</h2>
    );
    return (
      hasItems && (
        <section className="section-tiles layout-section">
          <CollapsableAnchorSection
            header={header}
            anchorId={id}
            collapsed={collapsed}
          >
            <ItemList
              section={section}
              items={items}
              styleModifier={ITEM_LIST_STYLE_MODIFIERS.GRID}
            />
            {!neverLoadMore && !isAmp && (
              <LoadMore
                key="load-more"
                infinite={infinite}
                loadNext={loadNextPage}
                isLoading={isLoading}
                immediateInfinite={immediateInfinite}
              />
            )}
          </CollapsableAnchorSection>
        </section>
      )
    );
  }
}

ProfileSectionItems.propTypes = {
  id: PropTypes.string.isRequired,
  infinite: PropTypes.bool,
  immediateInfinite: PropTypes.bool,
  isAmp: PropTypes.bool.isRequired,
  items: PropTypes.array,
  load: PropTypes.func.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  neverLoadMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  section: PropTypes.object,
  collapsed: PropTypes.bool,
};

ProfileSectionItems.defaultProps = {
  collapsed: false,
  immediateInfinite: false,
};

export default connector(connectAmp)(ProfileSectionItems);
