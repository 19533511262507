import { connect } from 'react-redux';
import { getProfileVideos } from '../redux/actions/profile-actions';
import ProfileSectionItems from '../components/profile-section-items';

import FlapUtil from 'Utils/content/flap-util';
import SectionUtil from 'Utils/content/section-util';
import withT from 'ComponentLibrary/hocs/withT';

const mapStateToProps = ({ sections }, { uid: userid, t }) => {
  const remoteId = FlapUtil.getProfileVideoRemoteid(userid);
  const section = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(remoteId, sections.entries),
  );
  const isLoading = SectionUtil.isSectionLoading(
    section.requestedRemoteId,
    sections.loading,
  );

  const { items, neverLoadMore } = section;
  return {
    section,
    items,
    neverLoadMore,
    isLoading,
    name: t('video_plural'),
    id: 'videos',
  };
};

const mapDispatchToProps = (dispatch, { uid: userid }) => ({
  load: () => dispatch(getProfileVideos(userid)),
  loadNextPage: () =>
    dispatch((_, getState) => {
      const { sections } = getState();
      const remoteId = `flipboard/list%2Fvideos%2F${userid}`;
      const section = FlapUtil.getSectionByRemoteId(remoteId, sections.entries);
      return dispatch(getProfileVideos(userid, section));
    }),
});

const ProfileVideosContainer = withT(
  connect(mapStateToProps, mapDispatchToProps)(ProfileSectionItems),
);

export default ProfileVideosContainer;
