import { connect } from 'react-redux';
import { getLikes } from '../redux/actions/profile-actions';
import ProfileSectionItems from '../components/profile-section-items';

import FlapUtil from 'Utils/content/flap-util';
import SectionUtil from 'Utils/content/section-util';
import withT from 'ComponentLibrary/hocs/withT';

const mapStateToProps = ({ sections, auth: { uid } }, { uid: likesUid, t }) => {
  const likesSectionId = FlapUtil.profileLikesRemoteId(
    uid === likesUid ? null : likesUid,
  );
  const section = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(likesSectionId, sections.entries),
  );
  const isLoading = SectionUtil.isSectionLoading(
    section.requestedRemoteId,
    sections.loading,
  );

  const { items, neverLoadMore } = section;
  return {
    section,
    items,
    neverLoadMore,
    isLoading,
    name: t('like_plural'),
    id: 'likes',
  };
};

const mapDispatchToProps = (dispatch, { uid: likesUid }) => ({
  load: () => {
    dispatch((_, getState) => {
      const {
        auth: { uid },
      } = getState();
      return dispatch(getLikes(uid, likesUid));
    });
  },
  loadNextPage: () =>
    dispatch((_, getState) => {
      const {
        auth: { uid },
        sections,
      } = getState();

      const likesSectionId = FlapUtil.profileLikesRemoteId(
        uid === likesUid ? null : likesUid,
      );

      const section = FlapUtil.getSectionByRemoteId(
        likesSectionId,
        sections.entries,
      );

      return dispatch(getLikes(uid, likesUid, section));
    }),
});

const ProfileLikesContainer = withT(
  connect(mapStateToProps, mapDispatchToProps)(ProfileSectionItems),
);

export default ProfileLikesContainer;
