import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import FlapUtil from 'Utils/content/flap-util';

// Components
import WebLink from '../../components/web-link';
import SectionTiles from '../../components/section-tiles';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

class ProfileStoryboards extends Component {
  constructor(props) {
    super(props);

    this.fetchNextPage = this.fetchNextPage.bind(this);
  }

  componentDidMount() {
    const { storyboardSection } = this.props;
    if (!storyboardSection || storyboardSection.placeholder) {
      this.fetchStoryboards();
    }
  }

  fetchStoryboards(nextPageKey, previousRawItems) {
    const {
      section: { userid },
      getSection,
    } = this.props;
    getSection(FlapUtil.getUserStoryboardSectionId(userid), {
      pageKey: nextPageKey,
      previousRawItems,
    });
  }

  fetchNextPage() {
    const { nextPageKey, previousRawItems } = this.props;
    this.fetchStoryboards(nextPageKey, previousRawItems);
  }

  render() {
    const { storyboardSection, items, neverLoadMore, isLoading, isAmp, t } =
      this.props;
    if (!items.length === 0) {
      return null;
    }
    return (
      <SectionTiles
        anchorId="storyboards"
        titleElement={
          <div className="layout-section__header">
            <h2 className="ui-text--title profile-storyboards__title">
              {t('storyboards')}
            </h2>
            {storyboardSection && storyboardSection.canonicalPath && (
              <WebLink
                name="storyboards-see-more"
                className="ui-text--supporting section-storyboards__see-more"
                href={storyboardSection.canonicalPath}
              >
                {t('go_to_feed')}
              </WebLink>
            )}
          </div>
        }
        title="Storyboards"
        sections={items}
        loadNext={neverLoadMore || isAmp ? null : this.fetchNextPage}
        loadingNext={isLoading}
      />
    );
  }
}

ProfileStoryboards.propTypes = {
  uid: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  storyboardSection: PropTypes.object,
  items: PropTypes.array,
  nextPageKey: PropTypes.string,
  previousRawItems: PropTypes.array,
  neverLoadMore: PropTypes.bool.isRequired,
  isAmp: PropTypes.bool.isRequired,
  t: PropTypes.func,
  getSection: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

ProfileStoryboards.defaultProps = {
  items: [],
  storyboardSection: null,
  nextPageKey: null,
  previousRawItems: [],
};

export default connector(connectAmp)(withT(ProfileStoryboards));
